import React from 'react';
import projects from '../assets/data/projects.json';
import PageHero from '../components/PageHero';
import Contact from '../components/Contact';
import TwoColumnLayout from '../components/TwoColumnLayout';

import ViraHero from '../assets/images/vira-hero.png';
import SparkRxHero from '../assets/images/SparkRx_hero.jpg';
import HuckleberryHero from '../assets/images/hb-multi-screen-mockup2.png';
import HappyHomeHero from '../assets/images/HH-hero.png';
import spark1 from '../assets/images/spark-content1.png';
import spark2 from '../assets/images/spark-content2.png';
import spark3 from '../assets/images/spark-content3.png';

const heroMap = {
  vira: ViraHero,
  sparkrx: SparkRxHero,
  huckleberry: HuckleberryHero,
  happyhome: HappyHomeHero,
};

const SparkRx = () => {
  const project = projects.find((p) => p.tag === 'sparkrx');

  if (!project) {
    return <p>Project not found!</p>;
  }

  return (
    <div className="sparkrx">
      <PageHero
        title={project.title}
        tagline={project.tagline}
        hero={heroMap[project.tag]} // Pass pre-imported image
      />
      
      {/* Additional content */}
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="row">
              
              <div className="col-lg-5 left-col prototype">

                <div class="heading"><div class="decobar">&nbsp;</div><h4><b>Try the Level 1 Prototype!</b></h4></div>
                <iframe width="100%" height="715" title="SparkRx Figma Prototype" src="https://embed.figma.com/proto/DlBkPavBOD3tAN4mMx5TAa/SparkRx-world-map-overview?page-id=1819%3A186999&node-id=1819-187048&node-type=frame&viewport=377%2C-620%2C0.47&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1819%3A186999&hide-proto-sidebar=1&embed-host=share" allowfullscreen></iframe>
              </div>
            
              <div className="col-lg-7 ps-4 right-col">
                <h4 className="light mb-5">{project.description}</h4>

                <div className="row">
                  
                  <div className="col-sm">
                    <p><b>The Challenge</b></p>
                    <p>Redesign and implement:</p>
                    <ul>
                      <li>A flexible, non-linear user experience that reduces frustration and dropout rates.</li>
                      <li>Gamified features to create an engaging, narrative-driven treatment journey.</li>
                      <li>Scalable design systems to ensure consistent and efficient updates.</li>
                    </ul>
                  </div>

                  <div className="col-sm">
                    <p><b>The Outcome</b></p>
                    <ul>
                      <li>Increased user retention by 30% within six months of the redesign.</li>
                      <li>Positive feedback from users and stakeholders on the app’s accessibility and engagement.</li>
                      <li>Enhanced scalability, allowing rapid iteration and deployment of future features.</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <TwoColumnLayout
        image={spark1}
        heading="Narrative-Driven Engagement"
        text="We identified that the app’s rigid, linear structure discouraged users from progressing through the therapeutic modules. To address this, I designed a gamified narrative system featuring level-based progression, allowing users to explore at their own pace while maintaining a sense of accomplishment. This created a more engaging and personalized experience."
        reverse={true} // Right image, left text

      />
      <TwoColumnLayout
        image={spark2}
        heading="Simplified Onboarding"
        text="Initial feedback revealed that users found the app’s onboarding process overwhelming, leading to early dropouts. I redesigned the onboarding flow into a series of interactive, bite-sized steps that introduce the app’s features gradually. This resulted in a 25% increase in successful onboarding completions."
        reverse={false} // Left image, right text
      />
      <TwoColumnLayout
        image={spark3}
        heading="Scalable Design Systems"
        text="The existing app lacked a cohesive design framework, making updates inconsistent and time-consuming. I led the creation of a component library and design system in Figma and Storybook, standardizing typography, icons, and UI components. This not only ensured visual consistency but also accelerated development timelines for new features."
        reverse={true} // Right image, left text
      /> 

      <Contact />
    </div>
  );
};

export default SparkRx;