const Contact = () => {
  return (
    <section className="Contact container">
      <div className="row">
        <div className="col">
          <h4 className="light mb-5">Want to know more about my design process?</h4>
          <a  className="btn btn-lg btn-outline-danger"         
              href="https://www.linkedin.com/in/anthonywalkerdesign/"
              target="_blank"
              rel="noopener noreferrer"
          > Message Me on Linkedin</a>
        </div>
      </div>

    </section>
  );
};

export default Contact;