import React from 'react';
import YouTubePlayer from './YouTubePlayer'; // Import the YouTubePlayer component

const PageHero = ({ title, tagline, hero, videoId }) => {
  return (
    <section className="page-hero container">
      <div className="row">
        <div className="col mx-2 mb-4">
          <h1><b>{title}</b></h1>
          <h4 className="tagline">{tagline}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col">
            {videoId ? (
              // Render the YouTubePlayer if videoId is provided
              <YouTubePlayer
                videoId={videoId}
                autoplay={0}
                controls={1}
                playsinline={1}
                mute={false}
              />
            ) : (
              // Render the hero image if no videoId is provided
              hero && (
                <img
                  src={hero}
                  alt={`${title} Hero`}
                  className="hero-image"
                />
              )
            )}
        </div>
      </div>
    </section>
  );
};

export default PageHero;

