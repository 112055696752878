import styles from './Footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer className="container">
      <div className="row">
        <div className="col text-left">
          <p>Copyright &copy; Anthony Walker {currentYear}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;