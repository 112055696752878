import projects from '../assets/data/projects.json';
import ProjectCard from '../components/ProjectCard';

// Import images directly
import ViraImage from '../assets/images/Vira-Smart-Phone-Mockup.png';
import ViraLogo from '../assets/images/ksana-health-logo.svg';
import SparkRxImage from '../assets/images/SparkRx-phone-shot.png';
import SparkRxLogo from '../assets/images/limbix-logo.svg';
import HuckleberryImage from '../assets/images/why-buy-wc-online.jpg';
import HuckleberryLogo from '../assets/images/Huckleberry Logo.svg';
import HappyHomeImage from '../assets/images/HH-preview.png';
import HappyHomeLogo from '../assets/images/HH-Logo-Orange.svg';

const imageMap = {
  vira: ViraImage,
  sparkrx: SparkRxImage,
  huckleberry: HuckleberryImage,
  happyhome: HappyHomeImage,
};

const logoMap = {
  vira: ViraLogo,
  sparkrx: SparkRxLogo,
  huckleberry: HuckleberryLogo,
  happyhome: HappyHomeLogo,
};

const WorkPage = () => {
  return (
    <div>
      <h1 className="display text-center">
        <b>
          Anthony Walker
          <br />
          Product Designer Based in S.F.
        </b>
      </h1>

      <div className="projects-grid">
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            title={project.title}
            tagline={project.tagline}
            image={imageMap[project.tag]}
            logo={logoMap[project.tag]}
            link={`/${project.tag}`}
            tag={project.tag}
          />
        ))}
      </div>
    </div>
  );
};

export default WorkPage;