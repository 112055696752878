import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import WorkPage from './pages/WorkPage';
import Vira from './pages/Vira';
import SparkRx from './pages/SparkRx';
import Huckleberry from './pages/Huckleberry';
import HappyHome from './pages/HappyHome';
import AboutPage from './pages/AboutPage';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Load the Google Analytics script
    const tag = document.createElement('script');
    tag.async = true;
    tag.src = 'https://www.googletagmanager.com/gtag/js?id=G-LYVVK9ZCVP';
    document.head.appendChild(tag);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', 'G-LYVVK9ZCVP');

    // Track page views on route changes
    gtag('event', 'page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  return null; // This component doesn't render anything
};

function App() {
  // Custom component to inject the class based on route
  const PageWrapper = ({ children }) => {
    const location = useLocation();

    // Map routes to class names
    const pageClassName =
      location.pathname === '/' ? 'work-page' :
      location.pathname === '/about' ? 'about-page' :
      location.pathname === '/vira' ? 'vira-page' :
      location.pathname === '/sparkrx' ? 'sparkrx-page' :
      location.pathname === '/huckleberry' ? 'huckleberry-page' :
      location.pathname === '/happyhome' ? 'happyhome-page' :
      '';

    return <div className={pageClassName}>{children}</div>;
  };

  return (
    <Router>
      <PageWrapper>
        <Layout>
          <Routes>
            <Route path="/" element={<WorkPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/vira" element={<Vira />} />
            <Route path="/sparkrx" element={<SparkRx />} />
            <Route path="/huckleberry" element={<Huckleberry />} />
            <Route path="/happyhome" element={<HappyHome />} />
          </Routes>
        </Layout>
      </PageWrapper>
    </Router>
  );
}

export default App;