import { useLocation } from 'react-router-dom';
import styles from './Header.module.scss';

// Import images directly
import logo from '../assets/images/walkerdesign-logo.svg';
import hamburger from '../assets/images/bars-solid.svg';

const Header = () => {
  const location = useLocation();

  return (
    <header className="container">
      <div className="row align-items-center">
        <div className="col text-left">
          <img src={logo} className="logo" alt="Walker Design Logo" />
        </div>
        <nav className="col text-right">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="/"
                className={location.pathname === '/' ? 'active' : ''}
              >
                Work
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/anthonywalkerdesign/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="/about"
                className={location.pathname === '/about' ? 'active' : ''}
              >
                About
              </a>
            </li>
          </ul>
          <a className="off-canvas btn btn-light" data-bs-toggle="offcanvas" href="#offcanvasmenu" role="button" aria-controls="offcanvasExample">
            <img src={hamburger} className="hamburger" alt="hamburger" />
          </a>
        </nav>
      </div>


      <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasmenu" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <div className="logo-container"><img src={logo} className="logo" alt="Walker Design Logo" /></div>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="/"
                className={location.pathname === '/' ? 'active' : ''}
              >
                Work
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/anthonywalkerdesign/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="/about"
                className={location.pathname === '/about' ? 'active' : ''}
              >
                About
              </a>
            </li>
          </ul>
        </div>
      </div>

    </header>
  );
};

export default Header;