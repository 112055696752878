import projects from '../assets/data/projects.json';
import PageHero from '../components/PageHero';
import Contact from '../components/Contact';
import TwoColumnLayout from '../components/TwoColumnLayout';

import ViraHero from '../assets/images/vira-hero.png';
import SparkRxHero from '../assets/images/SparkRx_hero.jpg';
import HuckleberryHero from '../assets/images/hb-multi-screen-mockup2.png';
import HappyHomeHero from '../assets/images/HH-hero.png';
import huck0 from '../assets/images/huck-content0.png';
import huck1 from '../assets/images/huck-content1.png';
import huck2 from '../assets/images/huck-content2.png';
import huck3 from '../assets/images/huck-content3.png';
import mbtlogo from '../assets/images/made-by-things-logo.svg';

const heroMap = {
  vira: ViraHero,
  spark: SparkRxHero,
  huckleberry: HuckleberryHero,
  happyhome: HappyHomeHero,
};

const Huckleberry = () => {
  const project = projects.find((p) => p.tag === 'huckleberry');

  if (!project) {
    return <p>Project not found!</p>;
  }

  return (
    <div className="huckleberry">
      <PageHero
        title={project.title}
        tagline={project.tagline}
        hero={heroMap[project.tag]} // Pass pre-imported image
      />
      {/* Additional content */}
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="row">
              
              <div className="col-md-6 left-col">
                <h4 className="light mb-5">{project.description}</h4>
              </div>
            
              <div className="col-md-6 ps-4 right-col">
                <p><b>The Challenge</b></p>
                <p>As the first hire and founding designer, I was tasked with establishing the company’s brand and product from the ground up. This involved creating a scalable and user-friendly insurance platform that could disrupt a legacy-dominated industry. The goal was to design a seamless 100% online quote flow, build trust through branding, and deliver a product that could scale rapidly while meeting the needs of small business owners.</p>
              
                <p><b>The Outcome</b></p>
                <ul>
                  <li>Conversions and policies sold continued a distinct upward trend at 30% month-over-month growth.</li>
                  <li>Secured an $18M Series A based on the strength of the product.</li>
                  <li>Strengthened the product and brand, leaving a lasting impact that contributed to the company’s subsequent acquisition by Paychex.</li>
                </ul>
              </div>
            
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          <div className="col">
              <img
                src={huck0}
                alt="Hucksters at the office"
                className="stylized"
              />
          </div>
        </div> 
      </section>

      <TwoColumnLayout
        image={huck1}
        heading="Intuitive Quote Flow"
        text="The traditional insurance process is often seen as confusing and time-consuming. To address this, I designed a step-by-step quote flow that reduced friction points and provided contextual guidance. This simplification led to a significant increase in completed self-serve applications without the need for human intervention."
        reverse={false} // Left image, right text
      />

      <TwoColumnLayout
        heading="Distinct Brand Identity"
        text="I established Huckleberry’s brand identity, including logo design, typography, icons, color systems, and corporate voice to stand out in a crowded insurance market. By emphasizing accessibility, friendliness and warmth in an traditionally more stern industry, the brand successfully balanced professionalism with approachability, creating a lasting impression with users and investors alike."
        reverse={true} // Right image, left text
        videoId="dEPoDa9AtZU"
        autoplay={1}      // Autoplay enabled
        controls={0}      // Hide controls
        playsinline={1}   // Inline play on mobile
        mute={true}       // Start muted
        loop={1}          // Loop the video
        captionContent={
          <div className="caption mbt">
            <p className="caption-text">Commercial by the awesome folks at</p>
            <a href="https://madebythings.com/work/huckleberry/" target="_blank" rel="noreferrer"><img src={mbtlogo} alt="Made by Things Logo" /></a>
          </div>
        }
      />

      <TwoColumnLayout
        image={huck3}
        heading="Branded Swag Design"
        text="I extended Huckleberry’s visual identity into company swag, designing custom apparel, stationery, printed materials and promotional items that embodied the brand’s approachable and friendly tone. These efforts not only fostered team pride but also enhanced the company’s presence at industry events and investor meetings."
        reverse={false} // Left image, right text
      /> 

      <Contact />

    </div>
  );
};

export default Huckleberry;