import React from 'react';
import YouTubePlayer from './YouTubePlayer';
import './TwoColumnLayout.scss';

const TwoColumnLayout = ({
  image,
  videoId,
  heading,
  text,
  reverse,
  autoplay = 0,
  controls = 1,
  playsinline = 1,
  mute = false,
  loop = 0,
  captionContent = null, // Optional custom content
}) => {
  return (
    <section className="two-column-layout container">
      <div className="row align-items-center justify-content-center">
        {/* Media Column */}
        <div className={`media col-md ${reverse ? 'order-2' : 'order-1'}`}>
          {videoId ? (
            <YouTubePlayer
              videoId={videoId}
              autoplay={autoplay}
              controls={controls}
              playsinline={playsinline}
              mute={mute}
              loop={loop}
            />
          ) : (
            <img src={image} alt={heading} className="img-fluid stylized" />
          )}

          {/* Caption Row - Renders Only If Provided */}
          {captionContent && (
            <div className="row caption-row mt-3">
              <div className="col">
                <div className="caption-content">
                  {captionContent}
                </div>
              </div>
            </div>
          )}          
        </div>

        {/* Text Column */}
        <div className={`text col-md ${reverse ? 'order-1' : 'order-2'}`}>
          <div className="text-content">
            <div className="heading">
              <div className="decobar">&nbsp;</div>
              <h4><b>{heading}</b></h4>
            </div>
            <p>{text}</p>
          </div>
        </div>
      </div>

    </section>
  );
};

export default TwoColumnLayout;