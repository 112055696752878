import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const YouTubePlayer = ({
  videoId,
  autoplay = 0,
  controls = 1,
  playsinline = 1,
  mute = false,
  loop = 0, // Default loop to off
}) => {
  useEffect(() => {
    // Load the IFrame Player API code asynchronously
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/player_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Create the YouTube player when the API is ready
    window.onYouTubePlayerAPIReady = () => {
      const player = new window.YT.Player('ytplayer', {
        videoId,
        playerVars: {
          autoplay,
          controls,
          playsinline,
          rel: 0,
          modestbranding: 1,
          loop,
          playlist: loop === 1 ? videoId : undefined, // Required to enable looping
        },
        events: {
          onReady: (event) => {
            if (autoplay === 1) {
              event.target.playVideo();
            }
            if (mute) {
              event.target.mute(); // Mute the video if mute is true
            }
          },
        },
      });
    };
  }, [videoId, autoplay, controls, playsinline, mute, loop]); // Re-run if props change

  return <div className="embed-container"><div id="ytplayer"></div></div>;
};

YouTubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  autoplay: PropTypes.number,
  controls: PropTypes.number,
  playsinline: PropTypes.number,
  mute: PropTypes.bool, 
  loop: PropTypes.number, // Add prop validation for loop
};

export default YouTubePlayer;