import React from 'react';
import projects from '../assets/data/projects.json';
import PageHero from '../components/PageHero';
import Contact from '../components/Contact';
import TwoColumnLayout from '../components/TwoColumnLayout';

import ViraHero from '../assets/images/vira-hero.png';
import SparkRxHero from '../assets/images/SparkRx_hero.jpg';
import HuckleberryHero from '../assets/images/hb-multi-screen-mockup2.png';
import HappyHomeHero from '../assets/images/HH-hero.png';
import hh0 from '../assets/images/hh-content0.png';
import hh1 from '../assets/images/hh-content1.png';
import hh2 from '../assets/images/hh-content2.png';
import hh3 from '../assets/images/hh-content3.png';
import hh4 from '../assets/images/hh-content4.png';

const heroMap = {
  vira: ViraHero,
  spark: SparkRxHero,
  huckleberry: HuckleberryHero,
  happyhome: HappyHomeHero,
};

const HappyHome = () => {
  const project = projects.find((p) => p.tag === 'happyhome');

  if (!project) {
    return <p>Project not found!</p>;
  }

  return (
    <div className="happy-home">
      <PageHero
        title={project.title}
        tagline={project.tagline}
        videoId="DwFWZCsFyoc" 
        reverse={true}
        autoplay={1}      // Autoplay enabled
        controls={0}      // Hide controls
        playsinline={1}   // Inline play on mobile
        mute={true}       // Start muted
        loop={1}          // Loop the video
      />

      {/* Additional content */}
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="row">
              
              <div className="col-md-6 left-col">
                <h4 className="light mb-5">{project.description}</h4>
              </div>
            
              <div className="col-md-6 ps-4 right-col">
                <p><b>The Challenge</b></p>
                <p>I spearheaded efforts to align the company’s visual and functional design across platforms. Challenges included:</p>
                <ul>
                  <li>Creating a cohesive and recognizable brand identity in a competitive home services market.</li>
                  <li>Developing high-fidelity prototypes to drive software and feature development and enhance cross-platform user engagement.</li>
                  <li>Redesigning the internal software suite to improve usability and aesthetic appeal for operational teams.</li>
                </ul>
              
                <p><b>The Outcome</b></p>
                <p>Successfully launched 1.0 customer facing product and redesigned the internal Home Manager software suite.</p>
              </div>
            
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          <div className="col">
              <img
                src={hh0}
                alt="Happy Home Webapp"
                className="stylized"
              />
          </div>
        </div> 
      </section>

      <section>
        <div className="row">
          <div className="col">
              <img
                src={hh1}
                alt="Happy Home UX Ecosystem"
                className="stylized"
              />
          </div>
        </div> 
      </section>      

      <TwoColumnLayout
        image={hh2}
        heading="Brand Revitalization"
        text="I led the rebranding efforts, crafting a fresh and cohesive visual identity that resonated with customers. From digital assets to print materials, I ensured that every touchpoint reflected the company’s commitment to simplicity and trustworthiness."
        reverse={true} // Right image, left text

      />
      <TwoColumnLayout
        image={hh3}
        heading="High-Fidelity Prototypes"
        text="To support the company’s digital evolution, I developed high-fidelity prototypes that ensured consistency across platforms. These prototypes enhanced the online user experience and provided a robust foundation for product development."
        reverse={false} // Left image, right text
      />
      <TwoColumnLayout
        image={hh4}
        heading="Internal Software Redesign"
        text="I redesigned the internal tools used by operational teams, improving usability and visual appeal. This overhaul streamlined workflows and empowered team members to manage customer interactions more effectively."
        reverse={true} // Right image, left text
      /> 

      <Contact />
    </div>
  );
};

export default HappyHome;