import { Link } from 'react-router-dom';
import ratio from '../assets/images/1-1.png';

const ProjectCard = ({ title, image, link, tag, logo }) => {
  return (
    <div className={`project-card ${tag}`}>
      <Link to={link}>
        <div className="project-card-content">
          <img src={ratio} className="blanko" alt="" />
          <img src={image} alt={`${title} screenshot`} className="project-card-image" />
          <h2 className="project-card-title">{title}</h2>
          <img src={logo} alt={`${title} logo`} className="project-card-logo" />
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;