import React from 'react';
import portrait from '../assets/images/Anthony-Portrait.jpg';

const AboutPage = () => {
  return (
    <div className="col-sm-12 col-lg-10 offset-lg-1">
      <div className="row">
        <div className="col-sm-12 col-md-6 left-col">
          <img src={portrait} className="portrait border-radius-large mb-3" alt="Anthony Walker Portrait" />
        </div>
        <div className="col-sm-12 col-md-6 right-col">
          <h1 className="display">Design is a problem-solving discipline in service of people. It lives in the liminal space between problem and solution, where I uncover insights, craft delightful aesthetic visions, and create meaningful value. My role is to deeply understand challenges and deliver solutions that empower users and enrich their lives.</h1>
          <h2>When I’m not designing, I enjoy making art.</h2>
          <a href="https://walkerfineart.org/" class="btn btn-lg btn-outline-danger" role="button" aria-pressed="false" target="_blank" rel="noreferrer">Art Work</a>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;