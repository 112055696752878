import React from 'react';
import projects from '../assets/data/projects.json';
import PageHero from '../components/PageHero';
import Contact from '../components/Contact';
import TwoColumnLayout from '../components/TwoColumnLayout';
import YouTubePlayer from '../components/YouTubePlayer';

import ViraHero from '../assets/images/vira-hero.png';
import SparkRxHero from '../assets/images/SparkRx_hero.jpg';
import HuckleberryHero from '../assets/images/hb-multi-screen-mockup2.png';
import HappyHomeHero from '../assets/images/HH-hero.png';
import drnick from '../assets/images/nick-circle.png';
import viraeco from '../assets/images/vira-ux-ecosystem.png';
import vira1 from '../assets/images/vira-content1.png';
import vira2 from '../assets/images/vira-content2.png';
import vira3 from '../assets/images/vira-content3.png';

const heroMap = {
  vira: ViraHero,
  spark: SparkRxHero,
  huckleberry: HuckleberryHero,
  happyhome: HappyHomeHero,
};

const ViraApp = () => {
  const project = projects.find((p) => p.tag === 'vira');

  if (!project) {
    return <p>Project not found!</p>;
  }

  return (
    <div className="vira-app">
      <PageHero
        title={project.title}
        tagline={project.tagline}
        hero={heroMap[project.tag]} // Pass pre-imported image
      />
      {/* Additional content */}
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-lg-4">

                <div className="video-star">
                  <div className='embed-container'>
                    <YouTubePlayer
                      videoId="_i_3YbdC_o8"
                      autoplay={0}
                      controls={0}
                      playsinline={1}
                      mute={false} // Mutes the video by default
                    />
                  </div>

                  <div className="description">
                      <div className="image">
                        <img
                          src={drnick}
                          alt="Dr Nick Allen"
                          className="portrait"
                        />
                      </div>
                      <div className="copy">
                        <p><b>The future of behavioral health care</b></p>
                        <p>Dr. Nick Allen reveals the power of digital transformation to bring therapy to you, wherever you are.</p>
                      </div>
                  </div>
                </div>

              </div>
            
              <div className="col-lg-8 ps-4">
                <h4 className="light mb-5">{project.description}</h4>

                <div className="row">
                  
                  <div className="col-sm">
                    <p><b>The Challenge</b></p>
                    <p>The project involved expanding the functionality of the Vira App while updating the Vira Pro portal to reflect these new capabilities. Key challenges included:</p>
                    <ul>
                      <li>Folding in suicide prevention features such as daily mood check-ins, a custom safety plan, new therapeutic and support activities, and crisis resources into Vira.</li>
                      <li>Ensuring these features were also visible and actionable across both platforms while balancing user needs and practitioner workflows.</li>
                      <li>Creating the UI/UX to support a suite of powerful new AI driven insights and features for practitioners in Vira Pro</li>
                      <li>Improving the onboarding flow, data visualizations + a general overhaul of product UI/UX</li>
                    </ul>
                  </div>

                  <div className="col-sm">
                    <p><b>The Outcome</b></p>
                    <ul>
                      <li>Successfully integrated suicide prevention features into the Vira App, enhancing user support during crises.</li>
                      <li>Updated the Vira Pro portal to align with new app features, providing practitioners with tools to monitor client progress and intervene effectively.</li>
                      <li>Delivered intuitive designs that maintained consistency between the app and practitioner portal, ensuring a seamless user-practitioner experience.</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          <div className="col">
              <img
                src={viraeco}
                alt="Vira UX Ecosystem"
                className="stylized"
              />
          </div>
        </div> 
      </section>

      <TwoColumnLayout
        image={vira1}
        heading="Suicide Prevention in Vira App"
        text="Integrated daily mood check-ins, a custom safety plan, therapeutic activities, and crisis resources into the app. These features empower users to manage their mental health proactively and provide critical support during crises."
        reverse={false} // Left image, right text
      />
      <TwoColumnLayout
        image={vira2}
        heading="Practitioner Tools in Vira Pro"
        text="Redesigned relevant sections of the Vira Pro portal to incorporate the new app features, including mood-tracking data and safety plan visibility, as well as a comprehensive overhaul of the UI. Practitioners now have actionable insights to better support their clients, as well as a suite of new AI-powered insights!"
        reverse={true} // Right image, left text
      />
      <TwoColumnLayout
        image={vira3}
        heading="Seamless Patient-Practitioner Experience"
        text="Ensured a cohesive and intuitive design language across both platforms, balancing the emotional and privacy needs of patients using the app with the practical requirements of practitioners."
        reverse={false} // Left image, right text
      />      

      <Contact />
    </div>
  );
};

export default ViraApp;